<template>
    <el-dialog
        title="库存出库"
        :visible.sync="detryDialogVisible"
        width="30%"
        top="10vh"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeDetryDialog('detryForm')"
    >
        <el-form
            :model="Detry"
            :rules="rulesDetry"
            ref="detryForm"
            label-width="120px"
        >
            <el-form-item label="出库重量" prop="weight">
                <el-input v-model="Detry.weight" clearable :placeholder="'剩余库存重量：' + max_detry_weight" @input="(e) => count_labour_money(e)"><i slot="suffix">KG</i></el-input>
            </el-form-item>
            <el-form-item label="出库托盘数" prop="tray_num">
                <el-input v-model="Detry.tray_num" clearable :placeholder="'剩余库存托盘数：' + max_detry_tray_num" @input="(e) => count_labour_money(e)"><i slot="suffix">个</i></el-input>
            </el-form-item>
            <el-form-item label="应收装车费率" prop="labour_money_rate" class="yingshou">
                <el-select
                    v-model="Detry.labour_money_rate"
                    placeholder="选择应收装车费率"
                    @change="(e) => count_labour_money(e)"
                    clearable
                    class="clientratec"
                >
                    <el-option
                        v-for="ClientStockRate in AllClientStockRate"
                        :key="ClientStockRate.id + ClientStockRate.rate"
                        :label="ClientStockRate | filter_rate"
                        :value="ClientStockRate.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="应收装车费" prop="labour_money" class="yingshou">
                <el-input v-model="Detry.labour_money" readonly><i slot="suffix">元</i></el-input>
            </el-form-item>
            <el-form-item label="应付装车费率" prop="pay_labour_money_rate" class="yingfu">
                <el-select
                    v-model="Detry.pay_labour_money_rate"
                    placeholder="选择应付装车费率"
                    @change="(e) => count_labour_money(e)"
                    clearable
                    class="clientratec"
                >
                    <el-option
                        v-for="ClientStockRate in AllClientStockRate2"
                        :key="ClientStockRate.id + ClientStockRate.rate"
                        :label="ClientStockRate | filter_rate"
                        :value="ClientStockRate.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="应付装车费" prop="pay_labour_money" class="yingfu">
                <el-input v-model="Detry.pay_labour_money" readonly><i slot="suffix">元</i></el-input>
            </el-form-item>
            <el-form-item label="应收其他费用" prop="other_money" class="yingshou">
                <el-input v-model="Detry.other_money" size="small" clearable><i slot="suffix">元</i></el-input>
            </el-form-item>
            <el-form-item label="应付其它费用" prop="pay_money" class="yingfu">
                <el-input v-model="Detry.pay_money" size="small" clearable><i slot="suffix">元</i></el-input>
            </el-form-item>
            <el-form-item label="关联出库单" prop="with_detry_bill_id">
                <el-select
                    v-model="Detry.with_detry_bill_id"
                    filterable
                    clearable
                    placeholder="选择出库单"
                    class="with_detry_bill_idc"
                >
                    <el-option
                        v-for="item in detry_bill_list"
                        :key="item.name + '(车牌号：' + item.car_num + ')'"
                        :label="item.name + '(车牌号：' + item.car_num + ')'"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="note">
                <el-input type="textarea" :rows="3" v-model="Detry.note"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
                <el-button
                    type="success"
                    plain
                    @click="submitDetry('detryForm')"
                    >确定</el-button
                >
                <el-button
                    plain
                    @click="closeDetryDialog('detryForm')"
                    >取消</el-button
                >
        </div>
    </el-dialog>
</template>

<script>
import { stock_detry_request } from '@/network/WareHouse/stock.js'

export default {
    name: 'StockDetryComponent',
    data() {
        return {
            Detry: {
                weight: '',
                tray_num: '',
                labour_money_rate: '',
                labour_money: 0,
                pay_labour_money_rate: '',
                pay_labour_money: 0,
                other_money: 0,
                pay_money: 0,
                with_detry_bill_id: '',
                note: ''
            },
            rulesDetry: {
                weight: [
                    {
                        required: true,
                        message: '请填写出库重量',
                        trigger: 'blur',
                    },
                ],
                tray_num: [
                    {
                        required: true,
                        message: '请填出库托盘数',
                        trigger: 'blur',
                    }
                ],
                labour_money_rate: [
                    {
                        required: true,
                        message: '请选择应收装车费率',
                        trigger: 'blur',
                    }
                ],
                labour_money: [
                    {
                        required: true,
                        message: '请填写应收装车费',
                        trigger: 'blur',
                    }
                ],
                pay_labour_money_rate: [
                    {
                        required: true,
                        message: '请选择应付装车费率',
                        trigger: 'blur',
                    }
                ],
                pay_labour_money: [
                    {
                        required: true,
                        message: '请填写应付装车费',
                        trigger: 'blur',
                    }
                ],
                other_money: [
                    {
                        required: true,
                        message: '请填写应收其他费用',
                        trigger: 'blur',
                    }
                ],
                pay_money: [
                    {
                        required: true,
                        message: '请填写应付其它费用',
                        trigger: 'blur',
                    }
                ],
                with_detry_bill_id: [
                    {
                        required: true,
                        message: '请选择出库单',
                        trigger: 'blur',
                    }
                ],
            },
            AllClientStockRate: [],
            AllClientStockRate2: []
        }
    },
    computed: {},
    methods: {
        closeDetryDialog(formName) {
            this.$emit('exitDetryDialog')
            this.$refs[formName].resetFields()
        },
        submitDetry(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if(this.Detry.weight *1 > this.max_detry_weight) {
                    this.$message.error('出库重量超出库存总重量')
                    return
                }
                if(this.Detry.tray_num *1 > this.max_detry_tray_num) {
                    this.$message.error('出库托盘数超出库存总托盘数')
                    return
                }
                if(this.Detry.labour_money < 0) {
                    this.$message.error('应收装车费不能小于0')
                    return
                }

                this.Detry.with_stock_id = this.id
                stock_detry_request(this.Detry)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('添加成功!')
                            this.$emit('exitDetryDialog')
                            this.$emit('refresh')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closeDetryDialog(formName))
            })
        },
        count_labour_money(e) {
            let rate = 0
            let pay_rate = 0
            let weight = 0
            let tray_num = 0
            let rate_type = 2
            if(this.Detry.labour_money_rate !== '')
            {
                this.fAllClientStockRate.forEach(v => {
                    if(v.id === this.Detry.labour_money_rate) {
                        rate = v.rate
                        rate_type = v.type
                    }
                });
            }
            if(this.Detry.pay_labour_money_rate !== '')
            {
                this.fAllClientStockRate.forEach(v => {
                    if(v.id === this.Detry.pay_labour_money_rate) {
                        pay_rate = v.rate
                    }
                });
            }
            if(this.Detry.weight !== '') {
                weight = this.Detry.weight
            }
            if(this.Detry.tray_num !== '') {
                tray_num = this.Detry.tray_num
            }

            if(rate_type === 2) {
                this.Detry.labour_money = rate * weight / 1000
            } else if(rate_type === 3) {
                this.Detry.labour_money = rate * tray_num
            }

            this.Detry.pay_labour_money = pay_rate * weight / 1000
        }
    },
    filters: {
        filter_rate: v=>{
            if(v.type === 2) {
                return v.rate + '元/吨/次'
            } else if(v.type === 3) {
                return v.rate + '元/托/次'
            } else if(v.type === 4) {
                return v.rate + '元/吨/次'
            }
            
        },
    },
    props: {
        id:Number,
        detryDialogVisible: Boolean,
        detry_bill_list: Array,
        max_detry_weight: Number,
        max_detry_tray_num: Number,
        fAllClientStockRate: {
            type: Array,
            default() {
                return []
            },
        },
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        fAllClientStockRate(newVal) {
            this.AllClientStockRate = newVal.filter( v => {
                return v.type === 2 || v.type === 3
            })
            this.AllClientStockRate2 = newVal.filter( v => {
                return v.type === 4
            })
        },
    }
}
</script>

<style lang='less'>
.with_detry_bill_idc {
    width: 100%;
}
.clientratec {
    width: 100%;
}
.yingshou>.el-form-item__label {
    color:hsl(120, 76%, 46%);
}
.yingfu>.el-form-item__label {
    color:hsl(0, 79%, 48%);
}
</style>