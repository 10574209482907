<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="产品名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="品名搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="namec"
                ></el-input>
            </el-form-item>
            <el-form-item label="订单号" prop="batch_number">
                <el-input
                    v-model="form.batch_number"
                    placeholder="订单号"
                    @keyup.enter.native="search()"
                    clearable
                    class="informationc"
                ></el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specifications">
                <el-input
                    v-model="form.specifications"
                    placeholder="规格"
                    @keyup.enter.native="search()"
                    clearable
                    class="informationc"
                ></el-input>
            </el-form-item>
            <el-form-item label="入库单号" prop="with_entry_bill_name">
                <el-input
                    v-model="form.with_entry_bill_name"
                    placeholder="入库单号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="提单号" prop="bill_num">
                <el-input
                    v-model="form.bill_num"
                    placeholder="提单号"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="集装箱号" prop="box_num">
                <el-input
                    v-model="form.box_num"
                    placeholder="集装箱号"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="所属客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    filterable
                    placeholder="选择客户"
                    class="clientc"
                    clearable
                >
                    <el-option
                        v-for="client in fAllClient"
                        :key="client.name"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属仓库" prop="storeroom_id">
                <el-cascader
                    v-model="form.storeroom_id"
                    :options="storeroom_in_enterprise"
                    :props="{ expandTrigger: 'hover' }"
                    clearable
                    @change="(storeroom_id) => get_location(storeroom_id)"
                    class="storeroomc"
                >
                </el-cascader>
            </el-form-item>
            <el-form-item label="所在库位" prop="localtion_id">
                <el-select
                    v-model="form.localtion_id"
                    filterable
                    placeholder="选择库位"
                    class="localtionc"
                    clearable
                >
                    <el-option
                        v-for="localtion in location_in_storeroom"
                        :key="localtion.id"
                        :label="localtion.name"
                        :value="localtion.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button type="info" plain @click="output" v-if="$_has(output_auth)">导出库存明细</el-button>
                <el-button type="danger" plain @click="$emit('show_batch_detry')" v-if="$_has(sel_orders_auth)">批量放入出库单</el-button>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
import { enterprise_storeroom_request } from '@/network/enterprise.js'
import { location_list_request} from '@/network/list.js'

export default {
    name: 'StockSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name:'',
                batch_number: '',
                specifications: '',
                with_entry_bill_name: '',
                bill_num: '',
                box_num: '',
                client_id: '',
                storeroom_id: '',
                localtion_id: '',
                page: 1,
            },
            storeroom_in_enterprise: [],
            location_in_storeroom: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        get_enterprise_storeroom() {
            this.login_loading = true
            enterprise_storeroom_request()
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.storeroom_in_enterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        get_location(storeroom_id) {
            this.location_in_storeroom = []
            this.form.localtion_id = ''
            const storeroom = storeroom_id[storeroom_id.length -1]
            if (storeroom) {
                this.login_loading = true
                location_list_request({storeroom_id:storeroom})
                    .then((s) => {
                        this.login_loading = false
                        if (s.status === 0) {
                            this.location_in_storeroom = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
            }
        },
        output() {
            this.$emit('output', this.form)
        }
    },
    filters: {},
    props: {
        fAllClient:Array,
        sel_orders_auth: String,
        output_auth: String
    },
    created() {
        this.get_enterprise_storeroom()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.informationc {
    width: 280px;
}
.storeroomc {
    width: 300px;
}
.localtionc {
    width: 120px;
}
</style>