<template>
    <div>
        <el-dialog
            title="批量放入出库单"
            :visible.sync="batch_detry_visible"
            :append-to-body="true"
            width="95%"
            :center="true"
            top="3vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog('Form')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div class="batch_detry">
                <el-form
                    ref="Form"
                    label-width="100px"
                    size="mini"
                >
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="选择客户" prop="client_id">
                                <el-select
                                    v-model="client_id"
                                    filterable
                                    placeholder="选择客户"
                                    class="clientc"
                                    clearable
                                    @change="(e) => clientChange(e)"
                                >
                                    <el-option
                                        v-for="client in fAllClient"
                                        :key="client.name"
                                        :label="client.name"
                                        :value="client.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="关联出库单">
                                <el-select
                                    v-model="with_detry_bill_id"
                                    filterable
                                    clearable
                                    placeholder="选择出库单"
                                    class="with_detry_bill_idc"
                                >
                                    <el-option
                                        v-for="item in detry_bill_list"
                                        :key="item.name + '(车牌号：' + item.car_num + ')'"
                                        :label="item.name + '(车牌号：' + item.car_num + ')'"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="订单号搜索" prop="search_order">
                        <el-input v-model="search_order" placeholder="支持多个订单号同时搜索，用英文逗号隔开(不支持模糊查询)" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="产品搜索" prop="search_product">
                        <el-input v-model="search_product" placeholder="仅支持单个产品搜索（支持模糊查询）" clearable>
                            <el-button type="primary" round plain @click="search" slot="suffix">搜索</el-button>
                        </el-input>
                    </el-form-item>
                    <common-table-component
                        v-if="table_data.length>0"
                        :col_arr="col_arr"
                        :table_data="table_data"
                        table_size="mini"
                        table_height="320px"
                        button_name="选择"
                        @sel_row="sel_row"
                    ></common-table-component>
                    <fieldset class="fieldsetc" v-if="batch_detry.length>0">
                        <legend class="legendc">批量出库</legend>
                        <el-row>
                            <el-col :span="4" class="row_title">订单号</el-col>
                            <el-col :span="6" class="row_title">产品名称</el-col>
                            <el-col :span="4" class="row_title">库位</el-col>   
                            <el-col :span="4" class="row_title">出库重量(KG)</el-col>
                            <el-col :span="4" class="row_title">出库托盘数</el-col>
                            <el-col :span="2" class="row_title">　</el-col>
                        </el-row>
                        <el-row :gutter="20" v-for="(value,index) in batch_detry" :key="'批量出库' + index">
                            <el-col :span="4" class="row_content">{{value.order_name ? value.order_name : '　'}}</el-col>
                            <el-col :span="6" class="row_content">{{value.product_name}}</el-col>
                            <el-col :span="4" class="row_content">{{value.localtion_name}}</el-col>
                            <el-col :span="4" class="row_content">
                                <el-input size="mini" v-model="value.weight"></el-input>
                            </el-col>
                            <el-col :span="4" class="row_content">
                                <el-input size="mini" v-model="value.tray_num"><i slot="suffix">个</i></el-input>
                            </el-col>
                            <el-col :span="2" class="row_content">
                                <el-button type="danger" plain icon="el-icon-delete" circle size="small" @click.prevent="Del(value)"></el-button>
                            </el-col>
                        </el-row>
                        <hr/>
                        <el-row>
                            <el-col :offset="10" :span="4" class="row_count">合计</el-col>   
                            <el-col :span="4" class="row_count">{{count('weight') | div_thousandBitSeparator}}</el-col>
                            <el-col :span="4" class="row_count">{{count('tray_num') + " 个"}}</el-col>
                        </el-row>
                    </fieldset>
                </el-form>
            </div>
            <div slot="footer">
                <el-button
                    v-if="$_has(batch_detry_auth) && (batch_detry.length>0)"
                    type="primary"
                    plain
                    @click="go_batch_detry()"
                    >出库</el-button
                >
                <el-button
                    plain
                    @click="closeDialog('Form')"
                    >关闭</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { stock_sel_orders_request,stock_batch_detry_request } from '@/network/WareHouse/stock.js'
import { detry_bill_list_request } from '@/network/list.js'
import { thousandBitSeparator } from '@/assets/js/common.js'

import CommonTableComponent from '@/components/common/CommonTableComponent'

export default {
    name: 'BatchDetryComponent',
    data() {
        return {
            login_loading: false,
            search_order: '',
            search_product: '',
            with_detry_bill_id:'',
            client_id: '',
            batch_detry: [],
            detry_bill_list: [],
            table_data: [],
            col_arr: [
                {
                    prop: 'order_name',
                    label: '订单号',
                    minWidth:'150px'
                },{
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth:'280px'
                },{
                    prop: 'localtion_name',
                    label: '库位',
                    minWidth:'80px'
                },{
                    prop: 'weight',
                    label: '重量(KG)',
                    minWidth:'100px'
                },{
                    prop: 'tray_num',
                    label: '托盘数(个)',
                    minWidth:'100px'
                }
            ],
        }
    },
    computed: {},
    methods: {
        closeDialog() {
            this.search_order = ''
            this.search_product = '',
            this.with_detry_bill_id = ''
            this.client_id = '',
            this.batch_detry = [],
            this.detry_bill_list = []
            this.table_data = []
            this.$emit('exitDialog')
        },
        search() {
            const cond = {
                client_id: this.client_id,
                search_order: this.search_order,
                search_product: this.search_product
            }
            stock_sel_orders_request(cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.table_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        Del(v) {
            const index = this.batch_detry.indexOf(v)
            if (index !== -1) {
                this.batch_detry.splice(index, 1)
                this.table_data.splice(v.table_index,0,v)
            }
        },
        go_batch_detry() {
            let arr = []
            this.batch_detry.forEach(v => {
                arr.push({
                    with_detry_bill_id: this.with_detry_bill_id,
                    with_stock_id: v.id,
                    weight: v.weight,
                    tray_num: v.tray_num
                })
            });
            
            this.login_loading = true
            stock_batch_detry_request({client_id: this.client_id,detry_arr: arr})
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('批量出库成功!')
                        this.closeDialog()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                // .finally(() => this.closeDialog())
        },
        get_detry_bill_list(client_id) {
            detry_bill_list_request({client_id:client_id,state:0})
                .then((s) => {
                    if (s.status === 0) {
                        this.detry_bill_list = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        sel_row(row) {
            const table_index = this.table_data.indexOf(row)
            if (table_index !== -1) {
                row.table_index = table_index
                this.batch_detry.push(row)
                this.table_data.splice(table_index, 1)
            }
        },
        count(a) {
            return this.batch_detry.reduce( (s,v) => s + v[a]*1,0 )
        },
        clientChange(e) {
            this.search_order = ''
            this.search_product = '',
            this.with_detry_bill_id = ''
            this.batch_detry = [],
            this.detry_bill_list = []
            this.table_data = []

            if(e !== '') {
                this.get_detry_bill_list(e)
            } 
        }
    },
    filters: {
        div_thousandBitSeparator(v) {
            return thousandBitSeparator(v) + ' KG'
        }
    },
    props: {
        batch_detry_visible: Boolean,
        batch_detry_auth: String,
        fAllClient:Array,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonTableComponent
    },
    watch: {},
}
</script>

<style lang='less'>
.row_title{
    text-align: center;
    color: cornflowerblue;
}
.row_content{
    text-align: center;
    height: 28px;
    line-height: 28px;
}
.row_count{
    text-align: center;
    color: red;
    font-weight: bold;
}
.moneyc{
    text-align: right;
}
.fieldsetc {
    margin-top: 20px;
}
.legendc {
    color: #921AFF;
}
.batch_detry .el-input__inner{
    text-align: center;
}
.clientc {
    width: 100%;
}
</style>