<template>
    <div v-loading.fullscreen.lock="loading">
        <stock-search-bar-component
            sel_orders_auth="批量放入出库单"
            output_auth="导出库存明细"
            :fAllClient="fAllClient"
            @search="get_stock_index"
            @show_batch_detry="batch_detry_visible = true"
            @output="output_stock"
        ></stock-search-bar-component>
        <common-table-component
            details_auth="库存详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_stock"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_stock_index"
        ></common-page-component>
        <stock-edit-component
            :id="fid"
            detry_auth="库存出库"
            mvtry_auth="库存移库"
            :dialogFormVisible="fdialogFormVisible"
            :stock_details_data="fstock_details_data"
            @exitStockDialog="dialogExit"
            @refresh_details="details_stock"
        ></stock-edit-component>
        <batch-detry-component
            batch_detry_auth="批量出库"
            :batch_detry_visible="batch_detry_visible"
            :fAllClient="fAllClient"
            @exitDialog="batch_detry_visible = false"
        ></batch-detry-component>
    </div>
</template>

<script>
import { stock_index_request,stock_details_request } from '@/network/WareHouse/stock.js'
import { client_list_request} from '@/network/list.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import StockSearchBarComponent from '@/components/WareHouse/Stock/StockSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import StockEditComponent from '@/components/WareHouse/Stock/StockEditComponent'
import BatchDetryComponent from '@/components/WareHouse/Stock/BatchDetryComponent'

export default {
    name: 'StockView',
    data() {
        return {
            fid: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                batch_number: '',
                specifications: '',
                with_entry_bill_name: '',
                bill_num: '',
                box_num: '',
                client_id: '',
                storeroom_id: '',
                localtion_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'entry_date',
                    label: '入库日期',
                    minWidth: '100px'
                },{
                    prop: 'with_entry_bill_name',
                    label: '关联入库单',
                    minWidth: '150px'
                },{
                    prop: 'name',
                    label: '品名',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },{
                    prop: 'weight',
                    label: '重量(KG)',
                    minWidth: '100px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['weight']))
                    }
                },{
                    prop: 'tray_num',
                    label: '托盘数',
                    minWidth: '80px',
                    editdata(row) {
                        return row['tray_num'] + '个'
                    }
                },{
                    prop: 'bill_num',
                    label: '提单号',
                    minWidth: '150px',
                    show_overflow_tooltip: true
                },{
                    prop: 'box_num',
                    label: '集装箱号',
                    minWidth: '120px',
                },{
                    prop: 'batch_number',
                    label: '订单号',
                    minWidth: '120px',
                },{
                    prop: 'specifications',
                    label: '规格',
                    minWidth: '120px',
                },{
                    prop: 'storeroom_name',
                    label: '所在仓库',
                    minWidth: '120px'
                },{
                    prop: 'localtion_name',
                    label: '所在库位',
                    minWidth: '120px'
                },{
                    prop: 'enterprise_name',
                    label: '所在公司',
                    minWidth: '260px'
                }
            ],
            fstock_details_data: {},
            fAllClient: [],
            batch_detry_visible: false
        }
    },
    computed: {},
    methods: {
        get_stock_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.batch_number = param.batch_number ?? this.cond.batch_number
            this.cond.specifications = param.specifications ?? this.cond.specifications
            this.cond.with_entry_bill_name = param.with_entry_bill_name ?? this.cond.with_entry_bill_name
            this.cond.bill_num = param.bill_num ?? this.cond.bill_num
            this.cond.box_num = param.box_num ?? this.cond.box_num
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.storeroom_id = param.storeroom_id === undefined ? this.cond.storeroom_id : param.storeroom_id[param.storeroom_id.length - 1]
            this.cond.localtion_id = param.localtion_id ?? this.cond.localtion_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            stock_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_stock(id) {
            this.fid = id
            this.fdialogFormVisible = true
            stock_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fstock_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
        },
        output_stock(param = {}) {
            const url = this.$store.state.baseURL 
            + 'stock/output'
            + '?name=' + param.name
            + '&batch_number=' + param.batch_number
            + '&specifications=' + param.specifications
            + '&with_entry_bill_name=' + param.with_entry_bill_name
            + '&bill_num=' + param.bill_num
            + '&box_num=' + param.box_num
            + '&client_id=' + param.client_id
            + '&storeroom_id=' + (typeof param.storeroom_id === 'string' ? '' : param.storeroom_id[param.storeroom_id.length - 1])
            + '&localtion_id=' + param.localtion_id
            window.open(url, '_blank')
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_stock_index()
        this.get_all_client()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        StockSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        StockEditComponent,
        BatchDetryComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>