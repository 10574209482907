<template>
    <div>
        <el-dialog
            title="库存详情"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="5vh"
            :close-on-click-modal="false"
            :before-close="() => closeStockDialog('StockEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Stock"
                    ref="StockEditForm"
                    label-width="150px"
                    size="small"
                >
                    <el-descriptions
                        class="margin-top"
                        :column="2"
                        :labelStyle="{'width':'120px','text-align':'center'}"
                        :contentStyle="{'text-align': 'center'}"
                        border
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <span>产品名称</span>
                            </template>
                            <span>{{Stock.name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>规格</span>
                            </template>
                            <span>{{Stock.specifications}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>订单号</span>
                            </template>
                            <span>{{Stock.batch_number}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>客户名称</span>
                            </template>
                            <span>{{Stock.client_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>库存重量</span>
                            </template>
                            <span>{{Stock.weight | diy_weight}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>库存托盘数</span>
                            </template>
                            <span>{{Stock.tray_num ? Stock.tray_num + '个' : ''}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>入库单号</span>
                            </template>
                            <span>{{Stock.with_entry_bill_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>入库时间</span>
                            </template>
                            <span>{{Stock.with_entry_bill_date}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>提单号</span>
                            </template>
                            <span>{{Stock.bill_num}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>所在公司</span>
                            </template>
                            <span>{{Stock.enterprise_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>所在仓库</span>
                            </template>
                            <span>{{Stock.storeroom_name}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <span>所在库位</span>
                            </template>
                            <span>{{Stock.localtion_name}}</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form>
                <div v-if="Stock.locking.length>0">
                    <fieldset class="mvtry_fieldset">
                        <legend class="legend_detry">出库单锁定库存</legend>
                        <common-table-component
                            table_size="mini"
                            table_height="200px"
                            :col_arr="fcol_arr"
                            :table_data="Stock.locking"
                        ></common-table-component>
                    </fieldset>
                </div>
                <div v-if="Stock.locking_mvtry.length>0">
                    <fieldset class="mvtry_fieldset">
                        <legend class="legend_mvtry">移库单锁定库存</legend>
                        <common-table-component
                            table_size="mini"
                            table_height="200px"
                            :col_arr="mvcol_arr"
                            :table_data="Stock.locking_mvtry"
                        ></common-table-component>
                    </fieldset>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="$_has(detry_auth)"
                    type="primary"
                    plain
                    @click="show_detry()"
                    >出库</el-button
                >
                <el-button
                    v-if="$_has(mvtry_auth)"
                    type="warning"
                    plain
                    @click="show_mvtry()"
                    >移库</el-button
                >
                <el-button
                    plain
                    @click="closeStockDialog('StockEditForm')"
                    >关闭</el-button
                >
                <stock-detry-component
                    :id="id"
                    :detryDialogVisible="detryDialogVisible"
                    :detry_bill_list="detry_bill_list"
                    :max_detry_weight="max_detry_weight"
                    :max_detry_tray_num="max_detry_tray_num"
                    :fAllClientStockRate="fAllClientStockRate"

                    @exitDetryDialog="exitDetryDialog"
                    @refresh="refresh"
                ></stock-detry-component>
                <stock-mvtry-component
                    :id="id"
                    :mvtryDialogVisible="mvtryDialogVisible"
                    :mvtry_bill_list="mvtry_bill_list"
                    :storeroom_name="Stock.enterprise_name + '/' + Stock.storeroom_name"
                    :localtion_name="Stock.localtion_name"
                    :localtion_id="Stock.localtion_id"
                    :max_detry_weight="max_detry_weight"
                    :max_detry_tray_num="max_detry_tray_num"
                    @exitMvtryDialog="exitMvtryDialog"
                    @refresh="refresh"
                ></stock-mvtry-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import StockDetryComponent from './StockDetryComponent'
import StockMvtryComponent from './StockMvtryComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'

import { detry_bill_list_request,mvtry_bill_list_request,client_stock_rate_list_request} from '@/network/list.js'

import { thousandBitSeparator,fomatFloat } from '@/assets/js/common.js'

export default {
    name: 'StockEditComponent',
    data() {
        return {
            login_loading: false,
            Stock: {
                with_entry_stock_id: '',
                weight: 0,
                tray_num: 0,
                storage_rate: '',
                labour_money_rate: '',
                note: '',
                localtion_id: '',
                name: '',
                code: '',
                specifications: '',
                batch_number: '',
                with_entry_bill_id: '',
                with_entry_bill_name: '',
                with_entry_bill_date: '',
                client_id: '',
                client_name: '',
                bill_num: '',
                localtion_name: '',
                storeroom_id: '',
                storeroom_name: '',
                enterprise_id: '',
                enterprise_name: '',
                locking: [],
                locking_mvtry: []
            },
            detryDialogVisible: false,
            detry_bill_list: [],
            mvtryDialogVisible: false,
            mvtry_bill_list: [],
            fAllClientStockRate: [],
            fcol_arr: [
                {
                    prop: 'with_detry_bill_name',
                    label: '关联出库单',
                    width: '100px',
                },{
                    prop: 'weight',
                    label: '待出库重量(KG)',
                    width: '100px',
                    editdata(row) {
                        return thousandBitSeparator(row['weight'])
                    }
                },{
                    prop: 'tray_num',
                    label: '待出库托盘数(个)',
                    width: '100px',
                },{
                    prop: 'note',
                    label: '备注',
                    show_overflow_tooltip: true
                }
            ],
            mvcol_arr: [
                {
                    prop: 'with_mvtry_bill_name',
                    label: '关联移库单',
                    width: '100px',
                },{
                    prop: 'weight',
                    label: '待移库重量(KG)',
                    width: '100px',
                    editdata(row) {
                        return thousandBitSeparator(row['weight'])
                    }
                },{
                    prop: 'tray_num',
                    label: '待移库托盘数(个)',
                    width: '100px',
                },{
                    prop: 'note',
                    label: '备注',
                    show_overflow_tooltip: true
                }
            ]
        }
    },
    computed: {
        //出库移库共用
        max_detry_weight() {
            let max_detry_weight = this.Stock.weight
            if(this.Stock.locking.length > 0) {
                max_detry_weight = max_detry_weight - this.Stock.locking.reduce( (s,v) => s + v.weight,0)
            }
            if(this.Stock.locking_mvtry.length > 0) {
                max_detry_weight = max_detry_weight - this.Stock.locking_mvtry.reduce( (s,v) => s + v.weight,0)
            }

            return max_detry_weight
        },
        //出库移库共用
        max_detry_tray_num() {
            let max_detry_tray_num = this.Stock.tray_num
            if(this.Stock.locking.length > 0) {
                max_detry_tray_num = max_detry_tray_num - this.Stock.locking.reduce( (s,v) => s + v.tray_num,0)
            }
            if(this.Stock.locking_mvtry.length > 0) {
                max_detry_tray_num = max_detry_tray_num - this.Stock.locking_mvtry.reduce( (s,v) => s + v.tray_num,0)
            }

            return max_detry_tray_num
        }
    },
    methods: {
        closeStockDialog(formName) {
            this.$refs[formName].resetFields()
            this.$emit('exitStockDialog')
        },
        show_detry() {
            this.detryDialogVisible = true
            this.get_detry_bill_list()
            this.get_stock_rate()
        },
        exitDetryDialog() {
            this.detryDialogVisible = false
        },
        exitMvtryDialog() {
            this.mvtryDialogVisible = false
        },
        show_mvtry() {
            this.mvtryDialogVisible =true
            this.get_mvtry_bill_list()
        },
        get_detry_bill_list() {
            this.loading = true
            detry_bill_list_request({client_id:this.Stock.client_id,state:0})
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.detry_bill_list = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_mvtry_bill_list() {
            this.loading = true
            mvtry_bill_list_request({state:0})
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.mvtry_bill_list = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_stock_rate() {
            client_stock_rate_list_request({client_id: this.Stock.client_id})
                .then((s) => {
                    this.fAllClientStockRate = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh() {
            this.$emit('refresh_details',this.id)
        }
    },
    filters: {
        diy_weight(v) {
            return v ? thousandBitSeparator(fomatFloat(v)) + ' KG' : ''
        }
    },
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        stock_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        detry_auth: String,
        mvtry_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        StockDetryComponent,
        CommonTableComponent,
        StockMvtryComponent
    },
    watch: {
        stock_details_data(newVal) {
            this.Stock = newVal
        },
    },
}
</script>

<style lang='less'>
.details>.el-form-item__label {
    color:cornflowerblue;
}
.legend_detry {
    color: #FF0000;
}
.legend_mvtry {
    color: #E6A23C;
}
.mvtry_fieldset {
    margin-top: 10px;
}
</style>