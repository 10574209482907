<template>
<el-dialog
        title="库存移库"
        :visible.sync="mvtryDialogVisible"
        width="40%"
        top="10vh"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => closeMvtryDialog('mvtryForm')"
    >
        <el-form
            :model="Mvtry"
            :rules="rulesMvtry"
            ref="mvtryForm"
            label-width="100px"
        >
            <el-form-item label="目前所在仓库">
                <el-input v-model="Mvtry.before_storeroom_name" :readonly="true">{{Mvtry.before_storeroom_name}}</el-input>
            </el-form-item>
            <el-form-item label="目前所在库位">
                <el-input v-model="Mvtry.before_localtion_name" :readonly="true">{{Mvtry.before_localtion_name}}</el-input>
            </el-form-item>
            <el-form-item label="目标仓库" prop="after_storeroom_id">
                <el-cascader
                    v-model="Mvtry.after_storeroom_id"
                    :options="storerooms"
                    :props="{ expandTrigger: 'hover' }"
                    @change="(e) => storeroomChange(e)"
                    clearable
                    style="width: 100%"
                >
                </el-cascader>
            </el-form-item>
            <el-form-item label="目标库位" prop="after_localtion_id">
                <el-select
                    v-model="Mvtry.after_localtion_id"
                    placeholder="选择库位"
                    class="after_localtion_idc"
                    clearable
                >
                    <el-option
                        v-for="r in localtions"
                        :key="r.id"
                        :label="r.name"
                        :value="r.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="移库重量" prop="weight">
                <el-input v-model="Mvtry.weight" clearable :placeholder="'剩余库存重量：' + max_detry_weight"><i slot="suffix">KG</i></el-input>
            </el-form-item>
            <el-form-item label="移库托盘数" prop="tray_num">
                <el-input v-model="Mvtry.tray_num" clearable :placeholder="'剩余库存托盘数：' + max_detry_tray_num"><i slot="suffix">个</i></el-input>
            </el-form-item>
            <el-form-item label="关联移库单" prop="with_mvtry_bill_id">
                <el-select
                    v-model="Mvtry.with_mvtry_bill_id"
                    filterable
                    clearable
                    placeholder="选择移库单"
                    class="with_mvtry_bill_idc"
                >
                    <el-option
                        v-for="item in mvtry_bill_list"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="note">
                <el-input type="textarea" :rows="3" v-model="Mvtry.note"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
                <el-button
                    type="success"
                    plain
                    @click="submitMvtry('mvtryForm')"
                    >确定</el-button
                >
                <el-button
                    plain
                    @click="closeMvtryDialog('mvtryForm')"
                    >取消</el-button
                >
        </div>
    </el-dialog>
</template>

<script>
import { stock_mvtry_request } from '@/network/WareHouse/stock.js'
import { enterprise_storeroom_request } from '@/network/enterprise.js'
import { location_list_request } from '@/network/list.js'

export default {
    name:'StockMvtryComponent',
    data(){
        return {
            Mvtry: {
                before_storeroom_name: '',
                before_localtion_name: '',
                before_localtion_id: '',
                after_storeroom_id: '',
                after_localtion_id: '',
                weight: '',
                tray_num: '',
                with_mvtry_bill_id: '',
                note:''
            },
            rulesMvtry: {
                after_storeroom_id: [
                    {
                        required: true,
                        message: '请选择目标仓库',
                        trigger: 'blur',
                    },
                ],
                after_localtion_id: [
                    {
                        required: true,
                        message: '请选择目标库位',
                        trigger: 'blur',
                    },
                ],
                weight: [
                    {
                        required: true,
                        message: '请填写移库重量',
                        trigger: 'blur',
                    },
                ],
                tray_num: [
                    {
                        required: true,
                        message: '请填写移库托盘数',
                        trigger: 'blur',
                    },
                ],
            },
            storerooms: [],
            localtions: []
        }
    },
    computed:{},
    methods:{
        closeMvtryDialog(formName) {
            this.$emit('exitMvtryDialog')
            this.$refs[formName].resetFields()
        },
        get_enterprise_storeroom() {
            enterprise_storeroom_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.storerooms = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        submitMvtry(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                if(this.Mvtry.weight *1 > this.max_detry_weight) {
                    this.$message.error('移库重量超出库存总重量')
                    return
                }
                if(this.Mvtry.tray_num *1 > this.max_detry_tray_num) {
                    this.$message.error('移库托盘数超出库存总托盘数')
                    return
                }

                this.Mvtry.with_stock_id = this.id
                stock_mvtry_request(this.Mvtry)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('添加成功!')
                            this.$emit('exitMvtryDialog')
                            this.$emit('refresh')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => this.closeMvtryDialog(formName))
            })
        },
        storeroomChange(e) {
            if(e.length === 0) {
                this.localtions = []
                this.Mvtry.after_localtion_id = ''
                return
            }

            location_list_request({storeroom_id: e[e.length - 1]})
                .then((s) => {
                    if (s.status === 0) {
                        this.localtions = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{
        id:Number,
        mvtryDialogVisible: Boolean,
        mvtry_bill_list: Array,
        max_detry_weight: Number,
        max_detry_tray_num: Number,
        storeroom_name: String,
        localtion_name: String,
        localtion_id: {
            type: Number|String,
            default() {
                return 0
            },
        },
    },
    created(){
        this.get_enterprise_storeroom()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        localtion_id(newVal) {
            this.Mvtry.before_localtion_id = newVal
        },
        storeroom_name(newVal) {
            this.Mvtry.before_storeroom_name = newVal
        },
        localtion_name(newVal) {
            this.Mvtry.before_localtion_name = newVal
        },
    }
}
</script>

<style lang='less'>
.after_localtion_idc {
    width: 100%;
}
.with_mvtry_bill_idc {
    width: 100%;
}
</style>